jQuery(window).ready(function($) {

	// Cookie Notice
	//var cookieNotice = new CookieAccept();


	// Nav menu
	$('.site-header__nav__menu-toggle').on('click', function(e) {
		e.preventDefault();
	//	console.log('open menu');
		$('html').toggleClass('js-show-menu-overlay');

	});



	$(window).scroll(function() {
		if ($(window).width() >= 768) {
			var scrolltop = $(window).scrollTop();

			if (scrolltop >= 5) {
				$('#scroll-to-panel').fadeOut('slow');
			} else {
				$('#scroll-to-panel').fadeIn('slow');
			}
		}
	});



	// Smooth scrolling.
	var scrollingoffset = 0;

	$('a[href^="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top - scrollingoffset
				}, 1000);
				return false;
			}
		}
	});





	// Pin sub menu once scrolled
	var $anchor = $("#scroller-anchor");
	if ($anchor.length) {
		var $scroller = $('html');

		var move = function() {
			var st = $(window).scrollTop();
			var ot = $anchor.offset().top;
			if(st > ot) {
				$scroller.addClass('fixed-quick-links');
			} else {
				if(st <= ot) {
					$scroller.removeClass('fixed-quick-links');
				}
			}
		};
		$(window).scroll(move);
		move();
	}


	

	// // Animation
	$('.animate').viewportChecker({
		classToAdd: 'fade-up'
	});





	//console.log('loaded');

});
